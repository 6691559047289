import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription, forkJoin } from 'rxjs';

import { ApplicationError, Identity, Signin } from '../../../infrastructure/models';
import { ErrorStore, IdentityStore } from '../../../infrastructure/store';
import { ApplicationSettingsProvider } from '../../providers';
import { catchError, first } from 'rxjs/operators';
import { ToasterService } from '../../../infrastructure/services';
import { ExternalLoginProvider } from '../../../infrastructure/providers/external-login.provider';
import { AccountNameResolverProvider } from '../../../infrastructure/providers';
import { ContactEmailVerificationCase, ContactEmailVerificationCodeStatus } from '../../../app-admin/models/support/email-verification.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EmailVerificationDialogComponent } from '../../../app-admin/identity/email-verification-dialog/email-verification-dialog.component';
import { MfaTokenStorage } from '../../../infrastructure/providers/mfa-token-storage';
import { EmailVerificationProvider } from '../../../app-admin/providers';
import { SetEmailDialogComponent } from '../../../app-admin/identity/set-email-dialog/set-email-dialog.component';

@Component({
    selector: 'cb-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit, OnDestroy {
    public identity: Observable<Identity>;
    public isTakeSurvey = true;
    lastErrorSubscription: Subscription;
    lastError: Observable<ApplicationError>;
    signInData: Signin;
    emailVerificationDialogRef: MatDialogRef<EmailVerificationDialogComponent>;
    setEmailDialogRef: MatDialogRef<SetEmailDialogComponent>;

    constructor(
        private identityStore: IdentityStore,
        private errorStore: ErrorStore,
        private applicationSettingsProvider: ApplicationSettingsProvider,
        private toasterService: ToasterService,
        private accountNameResolverProvider: AccountNameResolverProvider,
        private dialog: MatDialog,
        private mfaTokenStorage: MfaTokenStorage,
        private emailVerificationProvider: EmailVerificationProvider,
        private externalLoginProvider: ExternalLoginProvider
    ) {
        this.identity = this.identityStore.identity;
        this.lastError = this.errorStore.lastError;
    }

    ngOnInit() {
        this.prepareForRedirect();

        this.lastErrorSubscription = this.lastError.subscribe((error: any) => {
            if (error
                && error.error
                && this.signInData
                && this.signInData.id
            ) {
                if (error.error.error === 'unverified_email') {
                    this.showEmailVerificationDialog(this.signInData, ContactEmailVerificationCase.LoginEmailVerification, '');
                } else if (error.error.error === 'invalid_mfa_email_token') {
                    this.showEmailVerificationDialog(this.signInData, ContactEmailVerificationCase.MfaLogin, '');
                } else if(error.error.error === 'invalid_email') {
                    this.showSetEmailDialog();
                }
            }
        });
    }

    prepareForRedirect() {
        this.identity.subscribe(i => {
            if (i && i.access_token) {
                this.identityStore.goToRedirectUri(i, true);
            }
        });
    }

    public onLogin(user: Signin) {
        this.signInData = user;
        const mfaToken = this.mfaTokenStorage.getMfaEmailToken(user.id, user.account);
        this.doLogin(mfaToken);
    }

    private doLogin(mfaEmailToken: string) {
        const data = Object.assign({}, this.signInData, {mfa_email_token: mfaEmailToken} );
        this.identityStore.doSignIn(data);
    }

    onSamlLogin(): void {
        forkJoin([
            this.applicationSettingsProvider.isSamlEnabled(),
            this.accountNameResolverProvider.getTakeSurveyAccount(),
        ])
            .pipe(
                first(),
                catchError(err => this.toasterService.showError({ message: 'Invalid account name' }, true)))
            .subscribe(([isEnabled, account]) => {
                if (isEnabled) {
                    const ssoLink = this.externalLoginProvider.getSamlSigninLink(account);
                    window.location.href = ssoLink;
                } else {
                    this.toasterService.showError({ message: 'SAML is disabled for your account' }, true)
                }
            });
    }

    private showEmailVerificationDialog(data: Signin, verificationCase: ContactEmailVerificationCase, email: string) {
        this.emailVerificationDialogRef = this.dialog.open(
            EmailVerificationDialogComponent,
            {
                width: '536px',
                data: {
                    contactId: data.id,
                    verificationCase: verificationCase,
                    email: email,
                    password: data.password
                }
            }
        );

        this.emailVerificationDialogRef.afterClosed().subscribe(data => {
            if (data && data.status == ContactEmailVerificationCodeStatus.Valid) {
                if (data.token) {
                    this.mfaTokenStorage.setMfaEmailToken(this.signInData.id, this.signInData.account, data.token);
                    this.doLogin(data.token);
                } else if(data.code) {
                    this.emailVerificationProvider.issueToken(this.signInData.id, data.code)
                    .pipe(first())
                    .subscribe(res => {
                        this.mfaTokenStorage.setMfaEmailToken(this.signInData.id, this.signInData.account, res.token);
                        this.doLogin(res.token);
                    });
                }
            }
        });
    }

    private showSetEmailDialog() {
        this.setEmailDialogRef = this.dialog.open(
            SetEmailDialogComponent,
            {
                width: '536px'
            }
        );

        this.setEmailDialogRef.afterClosed().subscribe(email => {
            if (email) {
                this.showEmailVerificationDialog(this.signInData, ContactEmailVerificationCase.LoginEmailVerification, email);
            }
        });
    }

    ngOnDestroy(): void {
        if (this.lastErrorSubscription) {
            this.lastErrorSubscription.unsubscribe();
        }
    }
}
